import {
  CsvProcessResult,
  Favorite,
  SetFavorite,
  UploadStatus,
} from "api/models";
import utils from "api/Utils";

const baseUrl = "api/favorite";

const getFavorites = async () => {
  return await utils.apiGet<Favorite[]>(baseUrl);
};

const addFavorite = async (favorite: SetFavorite) => {
  return await utils.apiCommand(baseUrl, favorite);
};

const deleteFavorite = async (favorite: SetFavorite) => {
  return await utils.apiCommand(
    `${baseUrl}/${favorite.supplierId}?supplierArticleNumber=${favorite.supplierArticleNumber}`,
    null,
    "DELETE"
  );
};

const downloadFavoriteTemplate = async () => {
  return await utils.apiDownload(`${baseUrl}/xlsxTemplate`, null);
};

const uploadFavorites = async (
  file: Blob | FileList,
  onUploadProgress: (
    progress: number,
    status: UploadStatus,
    result?: CsvProcessResult
  ) => void
) => {
  return utils.apiUpload(`${baseUrl}/upload`, file, onUploadProgress);
};

const favoriteService = {
  getFavorites,
  addFavorite,
  deleteFavorite,
  downloadFavoriteTemplate,
  uploadFavorites,
};
export default favoriteService;