import { Type, I18N } from "api/models";

export interface AssortmentFilter {
  filter: AssortmentSearch;
  i18n: I18N;
}

export interface ProductDetailFilter {
  supplierId: number;
  key: string;
  i18n: I18N;
}

export interface AssortmentSearch {
  category?: string;
  subCategory?: string;
  manufacturer?: string;
  freeText?: string;
  productKey?: number;
  skip?: number;
  take?: number;
  type?: Type;
  sortColumn: SortColumn;
  sortAscending: boolean;
  refresh: boolean;
}

export enum SortColumn {
  Name = "Name",
  MaterialCode = "SupplierArticleNumber",
  ArticleReference = "ArticleNumber",
}
