import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Brand, I18N, Url } from "api/models";
import { configService } from "api/services";

import { View } from "./View.enum";

export interface ConfigState {
  view: View;
  brand?: string;
  returnUrl?: string;
  termsUrl?: string;
  privacyUrl?: string;
}

const initialState: ConfigState = {
  view: View.List,
};

export const getBrand = createAsyncThunk(
  "configuration/get/brand",
  async (arg, thunkAPI) => {
    return await configService.getBrand();
  }
);

export const getPrivacyTermsUrls = createAsyncThunk(
  "configuration/get/urls",
  async (arg: I18N | undefined | null, thunkAPI) => {
    return await configService.getTermsPrivacyUrls(arg);
  }
);

const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setView: (state) => {
      switch (state.view) {
        case View.Grid:
          state.view = View.List;
          break;
        default:
          state.view = View.Grid;
          break;
      }
    },
    setReturnUrl: (state, action: PayloadAction<string | undefined>) => {
      state.returnUrl = action.payload;
    },
    clearPrivacyTermsUrls: (state) => {
      state.privacyUrl = undefined;
      state.termsUrl = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getBrand.fulfilled,
      (state, action: PayloadAction<Brand>) => {
        state.brand = action.payload.name;
      }
    );
    builder.addCase(
      getPrivacyTermsUrls.fulfilled,
      (state, action: PayloadAction<Url>) => {
        state.termsUrl = action.payload.terms;
        state.privacyUrl = action.payload.privacy;
      }
    );
  },
});

export const { setView, setReturnUrl, clearPrivacyTermsUrls } =
  configSlice.actions;
export default configSlice.reducer;
