const getParameterCI = (object: any, key: string) => {
  const k = Object.keys(object).find(
    (k) => k?.toLowerCase() === key?.toLowerCase()
  );
  if (k) return object[k];
};

const sortByCI = (key: string, sortAscending: boolean = true) => {
  return function (a: any, b: any) {
    const paramA = getParameterCI(a, key);
    const paramB = getParameterCI(b, key);

    if (!paramA || !paramB) return 0;

    let comparison = 0;

    if (paramA > paramB) comparison = 1;
    else if (paramA < paramB) comparison = -1;

    return sortAscending ? comparison : comparison * -1;
  };
};

export default { sortByCI };
