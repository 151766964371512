import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  CreateOrder,
  CreateOrderResponse,
  Order,
  OrderSearch,
  Voucher,
} from "api/models";
import { orderService, voucherService } from "api/services";
import { RootState } from "app";
import { setMessage } from "features/message";
import { shallowEqual } from "react-redux";

interface OrderState {
  list: Order[];
  totalCount: number;
  currentFilter: OrderSearch;
  createOrder: CreateOrder;
  createOrderResponse?: CreateOrderResponse | null;
  submitting: boolean;
  voucher?: Voucher;
}

const initialCurrentFilterState: OrderSearch = {
  skip: 0,
  take: 10,
};

const initialState: OrderState = {
  list: [],
  totalCount: 0,
  currentFilter: initialCurrentFilterState,
  createOrder: {},
  submitting: false,
};

export const getOrders = createAsyncThunk(
  "get/orders",
  async (orderSearch: OrderSearch, thunkAPI) => {
    return await orderService.get(orderSearch);
  }
);

export const getOrderById = createAsyncThunk(
  "get/orderById",
  async (id: number, thunkAPI) => {
    return await orderService.getById(id);
  }
);

export const createOrder = createAsyncThunk(
  "post/createOrder",
  async (
    orderCallback: (success: boolean) => void,
    { getState, dispatch, rejectWithValue }
  ) => {
    const { order, cart, i18n } = getState() as RootState;
    const response = await orderService.createOrder({
      ...order.createOrder,
      items: cart.items.map((i) => ({
        supplierId: i.supplierId,
        supplierArticleNumber: i.supplierArticleNumber,
        quantity: i.value,
      })),
    });

    if (response?.success) {
      orderCallback(true);
      return response;
    }

    orderCallback(false);

    const message =
      response && response?.errorMessages?.length > 0
        ? response.errorMessages[0]
        : i18n?.translations?.OrderNotCreated;
    dispatch(
      setMessage({
        status: "warning",
        message: message,
      })
    );

    return rejectWithValue(null);
  }
);

export const resendOrder = createAsyncThunk(
  "post/resendOrder",
  async (id: number, { dispatch }) => {
    await orderService.resendOrder(id);
    dispatch(getOrderById(id));
  }
);

export const getVoucher = createAsyncThunk(
  "get/voucher",
  async (voucher: string, _) => {
    return await voucherService.getByCode(voucher);
  }
);

export const validateVoucher = createAsyncThunk<
  string,
  { voucher: string; removeWhenInvalid?: boolean }
>(
  "get/voucherValidation",
  async ({ voucher, removeWhenInvalid }, { dispatch, rejectWithValue }) => {
    const response = await voucherService.validate(voucher);

    if (!removeWhenInvalid || !response.isValid)
      dispatch(
        setMessage({
          message:
            removeWhenInvalid && !response.isValid
              ? "VoucherRemoved"
              : response.message,
          status:
            !removeWhenInvalid && response.isValid
              ? "success"
              : removeWhenInvalid && !response.isValid
              ? "warning"
              : "error",
          timeOut: response.isValid ? 5000 : undefined,
        })
      );

    if (response.isValid) return voucher;

    return rejectWithValue(removeWhenInvalid);
  }
);

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setCurrentFilter: (state, action: PayloadAction<OrderSearch>) => {
      state.list = [];
      state.currentFilter = action.payload;
    },
    showMore: (state) => {
      const skip = state.currentFilter.skip + state.currentFilter.take;
      if (skip < state.totalCount) state.currentFilter.skip = skip;
    },
    clearOrders: (state) => {
      state.list = [];
      state.totalCount = 0;
      state.currentFilter = initialCurrentFilterState;
    },
    updateCreateOrderDetails: (state, action: PayloadAction<CreateOrder>) => {
      if (
        !shallowEqual(
          {
            comment: state.createOrder.comment,
            reference: state.createOrder.reference,
          },
          {
            comment: action.payload.comment,
            reference: action.payload.reference,
          }
        )
      ) {
        state.createOrder.comment = action.payload.comment;
        state.createOrder.reference = action.payload.reference;
      }
    },
    clearVoucher: (state) => {
      state.createOrder.voucher = "";
      state.voucher = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createOrder.pending, (state, _) => {
      state.submitting = true;
      state.createOrderResponse = undefined;
    });
    builder.addCase(createOrder.fulfilled, (state, action) => {
      state.createOrder = {};
      state.submitting = false;
      state.createOrderResponse = action.payload;
      state.voucher = undefined;
    });
    builder.addCase(createOrder.rejected, (state, _) => {
      state.submitting = false;
    });
    builder.addCase(resendOrder.pending, (state, _) => {
      state.submitting = true;
    });
    builder.addCase(resendOrder.fulfilled, (state, _) => {
      state.submitting = false;
    });
    builder.addCase(resendOrder.rejected, (state, _) => {
      state.submitting = false;
    });
    builder.addCase(getOrders.fulfilled, (state, action) => {
      state.list = [...state.list, ...action.payload.orders];
      state.totalCount = action.payload.totalCount;
    });
    builder.addCase(getOrderById.fulfilled, (state, action) => {
      const index = state.list.findIndex((o) => o.id === action.payload.id);

      if (index > -1) {
        state.list[index] = action.payload;
      } else state.list.push(action.payload);
    });
    builder.addCase(validateVoucher.fulfilled, (state, action) => {
      state.createOrder.voucher = action.payload;
    });
    builder.addCase(validateVoucher.rejected, (state, action) => {
      if (action.meta.rejectedWithValue) {
        state.createOrder.voucher = "";
        state.voucher = undefined;
      }
    });
    builder.addCase(getVoucher.fulfilled, (state, action) => {
      if (action.payload) {
        state.voucher = action.payload;
      }
    });
  },
});

export const selectMemoizedOrder = (id: number) =>
  createDraftSafeSelector(
    (state: RootState) => state.order.list,
    (orders) => orders.find((o) => o.id === id)
  );

export const {
  setCurrentFilter,
  showMore,
  clearOrders,
  updateCreateOrderDetails,
  clearVoucher,
} = orderSlice.actions;
export default orderSlice.reducer;
