import { Country, Language } from "api/models";
import utils from "api/Utils";

const baseUrl = "api/i18n";

const getTranslations = async (languageCode: string) => {
  return utils.apiGet<Record<string, string>>(baseUrl, {
    languageCode,
  });
};

const getLanguages = async () => {
  return await utils.apiGet<Language[]>(`${baseUrl}/languages`);
};

const getCountries = async () => {
  return await utils.apiGet<Country[]>(`${baseUrl}/countries`);
};

const i18nService = { getTranslations, getLanguages, getCountries };
export default i18nService;
