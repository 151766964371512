import { TermsNotAgreedStatusCode } from "api/errors";
import { createSlice, isRejected } from "@reduxjs/toolkit";

const termsSlice = createSlice({
  name: "error",
  initialState: {
    showTermsPrompt: false,
  },
  reducers: {
    agreeLicense: (state) => {
      state.showTermsPrompt = false;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isRejected, (state, action) => {
      if (action.error.message === TermsNotAgreedStatusCode.toString())
        state.showTermsPrompt = true;
    });
  },
});

export const { agreeLicense } = termsSlice.actions;
export default termsSlice.reducer;
