import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Contact, ContactDetail, UserProfile } from "api/models";
import { userService } from "api/services";
import { RootState } from "app";
import { setMessage } from "features/message";

export interface UserState {
  isAuthenticated?: boolean;
  profile?: UserProfile | null;
  contactinfo?: Contact;
  showBillTo: boolean;
}

const initialState: UserState = {
  showBillTo: false,
};

export const getUserProfile = createAsyncThunk(
  "get/userProfile",
  async (arg, thunkAPI) => {
    return await userService.getUserData();
  }
);

export const getContactInfo = createAsyncThunk(
  "get/contactInfo",
  async (arg, thunkAPI) => {
    return await userService.getContactInfo();
  }
);

export const setLanguage = createAsyncThunk(
  "post/language",
  async (languageCode: string, thunkAPI) => {
    await userService.setLanguage(languageCode);
    return languageCode;
  }
);

const isEmptyContact = (contact: ContactDetail | null): boolean => {
  return (
    !contact?.address &&
    !contact?.city &&
    !contact?.company &&
    !contact?.contactEmail &&
    !contact?.contactPerson &&
    !contact?.country &&
    !contact?.phone &&
    !contact?.state &&
    !contact?.zip
  );
};

export const updateContactInfo = createAsyncThunk(
  "post/contactInfo",
  async (
    { contact, autosave }: any,
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      if (autosave) {
        const { user } = getState() as RootState;

        if (!user.contactinfo) {
          return null;
        }
        if (!user.showBillTo || isEmptyContact(contact.billContact)) {
          contact.billContact = null;
        }
        if (JSON.stringify(user.contactinfo) === JSON.stringify(contact)) {
          return null;
        }
      }

      await userService.updateContactInfo(contact);
      dispatch(
        setMessage({
          message: "ContactInfoSuccess",
          status: "success",
          timeOut: 5000,
        })
      );
      return contact;
    } catch {
      dispatch(
        setMessage({
          message: "ContactInfoError",
          status: "error",
        })
      );
      return rejectWithValue(contact);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setShowBillTo: (state, action: PayloadAction<boolean>) => {
      state.showBillTo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getUserProfile.fulfilled,
      (state, action: PayloadAction<UserProfile | null>) => {
        state.isAuthenticated = !!action.payload;
        state.profile = action.payload;
      }
    );
    builder.addCase(
      getContactInfo.fulfilled,
      (state, action: PayloadAction<Contact>) => {
        state.contactinfo = action.payload;
        state.showBillTo = !!action.payload?.billContact;
      }
    );
    builder.addCase(
      setLanguage.fulfilled,
      (state, action: PayloadAction<string>) => {
        if (state.profile) state.profile.language = action.payload;
      }
    );
    builder.addCase(
      updateContactInfo.fulfilled,
      (state, action: PayloadAction<Contact | null>) => {
        if (state.contactinfo && action.payload) {
          state.contactinfo = action.payload;
        }
      }
    );
    builder.addCase(updateContactInfo.rejected, (state, action) => {
      if (state.contactinfo) {
        state.contactinfo = action.meta.arg;
      }
    });
  },
});

export const { setShowBillTo } = userSlice.actions;
export default userSlice.reducer;
