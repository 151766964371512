import { Brand, I18N, Url } from "api/models";
import utils from "api/Utils";

const baseUrl = "api/configuration";

const getBrand = async () => {
  return utils.apiGet<Brand>(`${baseUrl}/brand`);
};

const getTermsPrivacyUrls = async (i18n?: I18N | null) => {
  return utils.apiGet<Url>(`${baseUrl}/url`, i18n);
};

const configService = { getBrand, getTermsPrivacyUrls };
export default configService;
