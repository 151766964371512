import { Assortment, AssortmentSearch, I18N, Product } from "api/models";
import utils from "api/Utils";

const baseUrl = "api/assortment";

const get = async (filter: AssortmentSearch, i18n: I18N) => {
  return utils.apiGet<Assortment>(`${baseUrl}`, {
    category: filter.category,
    subCategory: filter.subCategory,
    manufacturer: filter.manufacturer,
    freeText: filter.freeText,
    productKey: filter.productKey,
    skip: filter.skip,
    take: filter.take,
    type: filter.type,
    sortColumn: filter.sortColumn,
    sortAscending: filter.sortAscending,
    ...i18n,
  });
};

const getAnonymous = async (filter: AssortmentSearch, i18n: I18N) => {
  return utils.apiGet<Assortment>(`${baseUrl}/anonymous`, {
    category: filter.category,
    subCategory: filter.subCategory,
    manufacturer: filter.manufacturer,
    freeText: filter.freeText,
    productKey: filter.productKey,
    skip: filter.skip,
    take: filter.take,
    type: filter.type,
    sortColumn: filter.sortColumn,
    sortAscending: filter.sortAscending,
    ...i18n,
  });
};

const getAnonymousByKey = async (key: string, i18n: I18N) => {
  return utils.apiGet<Product>(`${baseUrl}/${key}`, i18n);
};

const getByKey = async (supplierId: number, key: string, i18n: I18N) => {
  return utils.apiGet<Product>(`${baseUrl}/${supplierId}/${key}`, i18n);
};

const assortmentService = { get, getAnonymous, getByKey, getAnonymousByKey };
export default assortmentService;