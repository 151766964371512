import { Contact, UserProfile } from "api/models";
import utils from "api/Utils";

const baseUrl = "api/user";

const getUserData = async () => {
  return await utils.apiNullableGet<UserProfile | null>(baseUrl);
};

const getContactInfo = async () => {
  return await utils.apiGet<Contact>(`${baseUrl}/ContactInfo`);
};

const getLicenseAgreement = async () => {
  return await utils.apiNullableGet<{ html?: string }>(
    `${baseUrl}/LicenceAgreement`
  );
};

const setLanguage = async (language: string) => {
  return await utils.apiCommand(`${baseUrl}/Language`, { language });
};

const updateContactInfo = async (contact: Contact) => {
  return await utils.apiCommand(`${baseUrl}/ContactInfo`, contact);
};

const setAgreeLicense = async () => {
  return await utils.apiCommand(`${baseUrl}/AgreeLicense`, null);
};

const userService = {
  getUserData,
  setLanguage,
  getContactInfo,
  updateContactInfo,
  getLicenseAgreement,
  setAgreeLicense,
};
export default userService;
