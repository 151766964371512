import {
  Breadcrumbs,
  Link,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useAppSelector } from "app";
import { Routes } from "app/routes";
import { selectT9n, LocalizedNavLink } from "features/locale";
import React from "react";
import { RouteComponentProps } from "react-router-dom";

interface NavBreadcrumbsProps {
  routeProps: RouteComponentProps<{ [x: string]: string | undefined }>;
  hide?: boolean;
}
const NavBreadcrumbs = ({ routeProps, hide }: NavBreadcrumbsProps) => {
  const t9n = useAppSelector(selectT9n);

  const { match } = routeProps;
  const crumbs = Routes.filter(({ path }) => match.path.includes(path)).map(
    ({ path, ...rest }) => ({
      path: Object.keys(match.params).length
        ? Object.keys(match.params).reduce(
            (path, param) =>
              path.replace(`:${param}`, match.params[param] ?? ""),
            path
          )
        : path,
      ...rest,
    })
  );

  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  if (isSmallScreen || hide) {
    return null;
  }

  return (
    <Breadcrumbs sx={{ pb: 4 }}>
      {crumbs.map(({ name, path }, i) =>
        i + 1 === crumbs.length ? (
          <Link key={i} underline="none" color="secondary">
            {t9n?.[name]}
          </Link>
        ) : (
          <Typography key={i} color="primary">
            <LocalizedNavLink key={i} to={path}>
              {t9n?.[name]}
            </LocalizedNavLink>
          </Typography>
        )
      )}
    </Breadcrumbs>
  );
};

export default NavBreadcrumbs;
