import { Voucher, VoucherValidation } from "api/models";
import utils from "api/Utils";

const baseUrl = "api/voucher";

const getByCode = async (voucher: string) => {
  return await utils.apiNullableGet<Voucher>(baseUrl, { voucher });
};

const validate = async (voucher: string) => {
  return await utils.apiGet<VoucherValidation>(`${baseUrl}/Validate`, {
    voucher,
  });
};

const voucherService = { getByCode, validate };
export default voucherService;
