import "./Layout.scss";

import { Box, Container } from "@mui/material";
import React from "react";

import Footer from "./Footer";
import Nav from "./Nav";
import LocalizationSelection from "features/locale/LocalizationSelection";

interface LayoutProps {
  openLocaleDialog: (isOpen: boolean) => void;
}

const Layout = (props: React.PropsWithChildren<LayoutProps>) => {
  return (
    <Container maxWidth="xl" id="page">
      <LocalizationSelection openLocaleDialog={props.openLocaleDialog} />
      <Nav />
      {/* Mui container only accepts not nullable children */}
      <Box id="content">{props.children ? props.children : <></>}</Box>
      <Footer />
    </Container>
  );
};

export default Layout;
