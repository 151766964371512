import { combineReducers } from "@reduxjs/toolkit";
import assortmentReducer from "features/assortment/slice";
import cartReducer from "features/cart/slice";
import configReducer from "features/configuration/slice";
import favoriteReducer from "features/favorite/slice";
import i18nReducer from "features/locale/slice";
import messageReducer from "features/message/slice";
import orderReducer from "features/order/slice";
import termsReducer from "features/terms/slice";
import userReducer from "features/user/slice";

const rootReducer = combineReducers({
  i18n: i18nReducer,
  user: userReducer,
  assortment: assortmentReducer,
  cart: cartReducer,
  order: orderReducer,
  favorite: favoriteReducer,
  config: configReducer,
  message: messageReducer,
  terms: termsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
