import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { CartItem, UpsertCartItem } from "api/models";
import { cartService } from "api/services";
import { RootState } from "app";
import { setMessage } from "features/message";
import { validateVoucher } from "features/order";

interface CartState {
  items: CartItem[];
  itemsNotInAssortment: string[];
}

const initialState: CartState = {
  items: [],
  itemsNotInAssortment: [],
};

export const getCartItems = createAsyncThunk(
  "get/cartItems",
  async (arg, thunkAPI) => {
    return await cartService.getCartItems();
  }
);

export const addToCart = createAsyncThunk(
  "post/addToCart",
  async (cartItem: UpsertCartItem, { dispatch, rejectWithValue }) => {
    try {
      await cartService.addToCart(cartItem);
      return cartItem;
    } catch {
      dispatch(setMessage({ message: "CartError", status: "error" }));
      return rejectWithValue(null);
    }
  }
);

export const updateCartItem = createAsyncThunk<
  UpsertCartItem,
  UpsertCartItem,
  { state: RootState }
>(
  "put/updateCartItem",
  async (cartItem: UpsertCartItem, { dispatch, getState, rejectWithValue }) => {
    try {
      await cartService.updateCartItem(cartItem);
    } catch {
      dispatch(setMessage({ message: "CartError", status: "error" }));
      return rejectWithValue(null);
    }

    var voucher = getState().order.createOrder.voucher;
    if (voucher)
      dispatch(validateVoucher({ voucher, removeWhenInvalid: true }));

    return cartItem;
  }
);

export const deleteNonAssortmentCartItems = createAsyncThunk(
  "delete/cartItems",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      await cartService.deleteNonAssortemtCartItems();
      dispatch(getCartItems());
    } catch {
      dispatch(setMessage({ message: "CartError", status: "error" }));
      return rejectWithValue(null);
    }
  }
);

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getCartItems.fulfilled,
      (state, action: PayloadAction<CartItem[]>) => {
        state.items = action.payload.filter(
          (c) => c.supplierArticleNumber && c.name
        );
        state.itemsNotInAssortment = action.payload
          .filter((c) => c.supplierArticleNumber && !c.name)
          .map((c) => c.supplierArticleNumber);
      }
    );
    builder.addCase(
      addToCart.fulfilled,
      (state, action: PayloadAction<UpsertCartItem>) => {
        const index = state.items.findIndex(
          (c) =>
            c.supplierArticleNumber === action.payload.supplierArticleNumber &&
            c.supplierId === action.payload.supplierId
        );
        if (index > -1) {
          state.items[index].value += action.payload.value;
        } else {
          state.items.push({ ...action.payload });
        }
      }
    );
    builder.addCase(
      updateCartItem.fulfilled,
      (state, action: PayloadAction<UpsertCartItem>) => {
        const index = state.items.findIndex(
          (c) =>
            c.supplierArticleNumber === action.payload.supplierArticleNumber &&
            c.supplierId === action.payload.supplierId
        );

        if (index > -1) {
          if (action.payload.value > 0)
            state.items[index].value = action.payload.value;
          else state.items.splice(index, 1);
        }
      }
    );
  },
});

export const selectMemoizedCartItem = (id?: number | string) =>
  createDraftSafeSelector(
    (state: RootState) => state.cart.items,
    (cartItems) => {
      if (!id) return;
      return cartItems
        .map((c) => ({
          ...c,
          totalPrice: c?.price && c.value * c.price,
        }))
        .find((c) => c.id === id || c.supplierArticleNumber === id);
    }
  );

export const selectCartItemTotal = createDraftSafeSelector(
  (state: RootState) => state.cart.items,
  (cartItems) => {
    return cartItems.reduce((acc, cart) => acc + cart.value, 0);
  }
);

export const selectCartItemTotalPrice = createDraftSafeSelector(
  (state: RootState) => state.cart.items,
  (cartItems) => {
    return cartItems.reduce(
      (acc, cart) => acc + cart.value * (cart.price ?? 0),
      0
    );
  }
);

export default cartSlice.reducer;
