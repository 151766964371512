import { TermsNotAgreedStatusCode } from "api/errors";
import { CsvProcessResult, UploadStatus } from "api/models";
import { authService } from "api/services";

async function apiGet<T>(url: string, queryParams?: any): Promise<T> {
  const response = await apiQuery(url, queryParams);
  return response.json() as Promise<T>;
}

async function apiNullableGet<T>(
  url: string,
  queryParams?: any
): Promise<T | null> {
  const response = await apiQuery(url, queryParams);
  const contentType = response.headers.get("content-type");

  if (contentType && contentType.indexOf("application/json") !== -1)
    return response.json() as Promise<T>;

  return null;
}

async function apiCommand<T1, T2>(
  url: string,
  data: T1,
  method?: "POST" | "PUT" | "DELETE"
) {
  const returnUrl = window.location.pathname;
  const response = await fetch(url, {
    method: method ?? "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  handleErrorResponse(response, returnUrl);
  const contentType = response.headers.get("content-type");

  if (contentType && contentType.indexOf("application/json") !== -1)
    return response.json() as Promise<T2>;

  return null;
}

async function apiDownload<T>(url: string, data: T) {
  const returnUrl = window.location.pathname;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  handleErrorResponse(response, returnUrl);
  const filename = getFileName(response);
  return { file: await response.blob(), name: filename };
}

async function apiUpload(
  url: string,
  files: Blob | FileList,
  onUploadProgress: (
    progres: number,
    status: UploadStatus,
    result?: CsvProcessResult
  ) => void
) {
  const returnUrl = window.location.pathname;

  return new window.Promise((resolve, reject) => {
    let request = new XMLHttpRequest();
    request.open("POST", url);

    // upload progress event
    request.upload.addEventListener("progress", (e) => {
      let percent_completed = (e.loaded / e.total) * 100;
      onUploadProgress(percent_completed, UploadStatus.Pending, undefined);
    });

    // request uploaded event
    request.upload.addEventListener("load", () => {
      onUploadProgress(100, UploadStatus.Done, undefined);
      resolve(request.response);
    });

    request.upload.addEventListener("error", () => {
      onUploadProgress(0, UploadStatus.Error, undefined);
      reject(request.response);
    });

    request.onreadystatechange = function () {
      if (request.readyState === 4) {
        if (request.status === 401) authService.login(returnUrl);
        if (request.status === 403) window.location.href = "/unauthorized";

        if (request.status === 200 || request.status === 204) {
          onUploadProgress(-1, UploadStatus.Done, JSON.parse(request.response));
        } else onUploadProgress(-1, UploadStatus.Error, undefined);
      }
    };

    let formData = new FormData();

    if (files instanceof FileList)
      Array.from(files).forEach((file, i) => {
        formData.append(`file${i}`, file);
      });
    else formData.append("file", files);
    // send POST request to server
    request.send(formData);
  });
}

async function apiQuery(url: string, queryParams?: any) {
  const returnUrl = window.location.pathname;
  const urlWithParams = queryParams ? `${url}${queryString(queryParams)}` : url;
  const response = await fetch(urlWithParams);
  handleErrorResponse(response, returnUrl);
  return response;
}

// Will omit null/undefined values
const queryString = (query: { [key: string]: any } = {}) => {
  const qs = Object.entries(query)
    .filter((pair) => pair[1] !== undefined && pair[1] !== null)
    .map((pair) => pair.map((i) => encodeURIComponent(i)).join("="))
    .join("&");

  return qs && "?" + qs;
};

const handleErrorResponse = (response: Response, returnUrl: string) => {
  if (response.status === 401) authService.login(returnUrl);
  if (response.status === 403) window.location.href = "/unauthorized";
  if (response.status === TermsNotAgreedStatusCode) {
    throw new Error(TermsNotAgreedStatusCode.toString());
  }
  if (!response.ok) throw new Error(response.statusText);
};

const getFileName = (response: Response) => {
  let filename = "";
  const disposition = response.headers.get("Content-Disposition");
  if (
    disposition &&
    (disposition.indexOf("attachment") !== -1 ||
      disposition.indexOf("inline") !== -1)
  ) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, "");
    }
  }
  return filename;
};

export default { apiGet, apiNullableGet, apiCommand, apiDownload, apiUpload };
