import { I18N } from "api/models";
import { RootState, useAppDispatch, useAppSelector } from "app";
import { Routes } from "app/routes";
import { clearCurrentFilter } from "features/assortment";
import { getCartItems } from "features/cart";
import { getPrivacyTermsUrls, setReturnUrl } from "features/configuration";
import { getFavorites } from "features/favorite";
import { localeService, selectT9n } from "features/locale";
import LocalizationSelectionDialog from "features/locale/LocalizationSelectionDialog";
import LocalizedSwitch from "features/locale/LocalizedSwitch";
import Message from "features/message/Message";
import TermsPromptDialog from "features/terms/TermsPromptDialog";
import PrivateRoute from "features/user/PrivateRoute";
import React, { Suspense, useEffect, useState } from "react";
import {
  Redirect,
  Route,
  RouteComponentProps,
  useHistory,
  useLocation,
} from "react-router-dom";
import { cookieKeys, cookieService } from "services";

import Layout from "./components/Layout";
import NavBreadcrumbs from "./components/NavBreadcrumbs";

const App = () => {
  const t9n = useAppSelector(selectT9n);
  const isAuthenticated = useAppSelector(
    (state: RootState) => state.user.isAuthenticated
  );
  const showTermsPrompt = useAppSelector(
    (state: RootState) => state.terms.showTermsPrompt
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getCartItems());
      dispatch(getFavorites());
    }
  }, [dispatch, isAuthenticated]);

  const history = useHistory();
  const { pathname } = useLocation();
  const i18nCookie = cookieService.get(cookieKeys.i18n);
  const [openDialog, setOpenDialog] = useState(
    !localeService.checkIfValidI18nUrl(pathname) && !i18nCookie
  );
  const handleDialogClose = (value: I18N) => {
    if (value.country && value.lang) {
      setOpenDialog(false);
      cookieService.set(cookieKeys.i18n, JSON.stringify(value));
      history.push(
        localeService.correctI18nUrl(`/${value.country}/${value.lang}`)
      );
      dispatch(clearCurrentFilter());
      dispatch(getPrivacyTermsUrls(value));
    }
  };

  if (!t9n) return <></>;

  return (
    <Layout openLocaleDialog={(isOpen) => setOpenDialog(isOpen)}>
      <Suspense fallback={<div>Loading...</div>}>
        <LocalizedSwitch>
          {Routes.map(
            (
              {
                path,
                Component,
                componentProps,
                hideBreadCrumbs,
                isPrivateRoute,
                requiredRoles,
              },
              key
            ) => {
              return isPrivateRoute ? (
                <PrivateRoute
                  exact
                  path={path}
                  key={key}
                  requiredRoles={requiredRoles}
                  render={(props) => (
                    <Content
                      routeProps={props}
                      hideBreadCrumbs={hideBreadCrumbs}
                      Component={Component}
                      componentProps={componentProps}
                    />
                  )}
                />
              ) : (
                <Route
                  exact
                  path={path}
                  key={key}
                  render={(props) => {
                    const returnUrl = Routes.filter(
                      ({ path }) =>
                        props.match.path ===
                        `${localeService.getI18nBaseUrl()}${path}`
                    )
                      .filter(({ returnUrl }) => returnUrl)
                      .map(({ returnUrl }) => returnUrl)?.[0];
                    dispatch(setReturnUrl(returnUrl));
                    return (
                      <Content
                        routeProps={props}
                        hideBreadCrumbs={hideBreadCrumbs}
                        Component={Component}
                        componentProps={componentProps}
                      />
                    );
                  }}
                />
              );
            }
          )}
          {/* Fallback route */}
          <Route path="*">
            <Redirect to="/"></Redirect>
          </Route>
        </LocalizedSwitch>
      </Suspense>
      <LocalizationSelectionDialog
        open={openDialog}
        onClose={handleDialogClose}
      />
      <TermsPromptDialog open={showTermsPrompt} onClose={() => history.go(0)} />
    </Layout>
  );
};

interface ContentProps {
  routeProps: RouteComponentProps<any>;
  hideBreadCrumbs?: boolean;
  Component: React.ElementType;
  componentProps: any;
}
const Content = ({
  routeProps: props,
  hideBreadCrumbs,
  Component,
  componentProps,
}: ContentProps) => (
  <>
    <NavBreadcrumbs routeProps={props} hide={hideBreadCrumbs} />
    <Message />
    <Component {...props} {...componentProps} />
  </>
);

export default App;
