import { createTheme } from "@mui/material";
import { loadImage } from "themes/Mixins";

import Delete from "./icons/Delete.svg";
import Favorite from "./icons/Favorite.svg";
import FavoriteFilled from "./icons/Favorite-filled.svg";
import Grid from "./icons/Grid.svg";
import List from "./icons/List.svg";
import SortAsc from "./icons/Sort-ascending.svg";
import SortDesc from "./icons/Sort-descending.svg";
import ArrowRight from "./icons/ArrowRight.svg";
import logo from "./img/logo.png";
import home from "./img/home.png";
import { CommonTheme } from "themes/CommonTheme";

const primary = "#E63C28";
export const Theme = createTheme({
  ...CommonTheme,
  palette: {
    ...CommonTheme.palette,
    primary: {
      main: primary,
      light: "#f7dbd7",
    },
  },
  components: {
    ...CommonTheme.components,
    MuiCard: {
      styleOverrides: {
        root: {
          color: primary,
        },
      },
    },
  },
});

export const Styles = {
  "#brand-logo": loadImage(logo, "cover", 128, 91),
  "#home-img": loadImage(home, "cover", "18rem", "10rem"),
  ".grid-icon": loadImage(Grid, "auto", 24),
  ".list-icon": loadImage(List, "auto", 24),
  ".sort-asc-icon": loadImage(SortAsc, "auto", 24),
  ".sort-desc-icon": loadImage(SortDesc, "auto", 24),
  ".favorite-icon": loadImage(Favorite, "auto", 24),
  ".favorite-filled-icon": loadImage(FavoriteFilled, "auto", 24),
  ".delete-icon": loadImage(Delete, "auto", 24),
  ".arrow-right-icon": loadImage(ArrowRight, "auto", 24),
  a: { textDecoration: "none" },
  "#nav a.active": {
    color: primary,
    img: {
      filter:
        "invert(31%) sepia(85%) saturate(2968%) hue-rotate(347deg) brightness(93%) contrast(94%)",
    },
  },
  "#side-nav a.active": {
    color: primary,
  },
  "a.active": {
    color: primary,
  },
  ".bc-primary": {
    backgroundColor: primary,
  },
};
