import {
  Autocomplete,
  Button,
  Dialog,
  DialogTitle,
  TextField,
} from "@mui/material";
import { I18N } from "api/models";
import { RootState, useAppDispatch, useAppSelector } from "app";
import { clearPrivacyTermsUrls } from "features/configuration";
import { selectT9n, getTranslations, localeService } from "features/locale";
import React, { useEffect, useState } from "react";
import { shallowEqual } from "react-redux";

interface LocalizationSelectionDialogProps {
  open: boolean;
  onClose: (value: I18N) => void;
}
const LocalizationSelectionDialog = ({
  open,
  onClose,
}: LocalizationSelectionDialogProps) => {
  const [isFormValid, setIsFormValid] = useState(false);

  const defaultI18n = localeService.getI18nFromBrowser();
  const [formState, setFormState] = useState(defaultI18n);

  const languages = useAppSelector(
    (state: RootState) => state.i18n.languages,
    shallowEqual
  );

  const countries = useAppSelector(
    (state: RootState) => state.i18n.countries,
    shallowEqual
  );
  const t9n = useAppSelector(selectT9n);

  const dispatch = useAppDispatch();
  function handleChange(name: string, value?: string) {
    //Mui Select onChange event does not generate event.target.name

    if (!value) return;
    if (name === "lang") {
      dispatch(getTranslations(value));
    }
    setFormState({
      ...formState,
      [name]: value,
    });
  }

  const handleClose = (e: {}, reason?: string) => {
    // in MUI v5 disableBackDropClick was removed
    // this code is from https://mui.com/guides/migration-v4/#handling-breaking-changes
    if (reason !== "backdropClick") {
      onClose(formState);
    }
  };

  useEffect(() => {
    if (
      localeService.isValid(countries, formState.country) &&
      localeService.isValid(languages, formState.lang)
    ) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [formState, countries, languages]);

  if (!t9n || languages.length <= 0 || countries.length <= 0) return <></>;

  const selectedLanguage = languages.find(
    (l) => l.code.toLowerCase() === formState.lang?.toLowerCase()
  );
  const selectedCountry = countries.find(
    (l) => l.code.toLowerCase() === formState.country?.toLowerCase()
  );

  return (
    <Dialog disableEscapeKeyDown onClose={handleClose} open={open}>
      <DialogTitle>{t9n?.PleaseChooseLanguageCountry}</DialogTitle>

      <Autocomplete
        options={languages}
        getOptionLabel={(l) => l.nativeName}
        isOptionEqualToValue={(option, value) =>
          option.code.toLowerCase() === value.code.toLowerCase()
        }
        value={selectedLanguage}
        onChange={(e: any, newValue) => {
          handleChange("lang", newValue?.code);
          dispatch(clearPrivacyTermsUrls());
        }}
        sx={{ m: 2 }}
        renderInput={(params) => (
          <TextField {...params} label={t9n?.SelectLanguage} />
        )}
      />

      <Autocomplete
        options={countries}
        getOptionLabel={(l) => t9n[`Country.${l.code}`]}
        isOptionEqualToValue={(option, value) =>
          option.code.toLowerCase() === value.code.toLowerCase()
        }
        value={selectedCountry}
        onChange={(e: any, newValue) => {
          handleChange("country", newValue?.code);
          dispatch(clearPrivacyTermsUrls());
        }}
        sx={{ m: 2 }}
        renderInput={(params) => (
          <TextField {...params} label={t9n?.SelectCountry} />
        )}
      />

      <Button
        variant="contained"
        sx={{ m: 2 }}
        disabled={!isFormValid}
        onClick={handleClose}
      >
        {t9n?.Confirm}
      </Button>
    </Dialog>
  );
};

export default LocalizationSelectionDialog;
