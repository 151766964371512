import { ThemeOptions } from "@mui/material";

export const CommonTheme: ThemeOptions = {
  palette: {
    secondary: {
      main: "#808080",
    },
  },
  typography: {
    h1: {
      fontSize: "1.6rem",
    },
    h2: {
      fontSize: "1.4rem",
    },
    h3: {
      fontSize: "1.3rem",
    },
    h4: {
      fontSize: "1.2rem",
      fontWeight: "bold",
    },
    h5: {
      fontSize: "1.1rem",
    },
    h6: {
      fontSize: "1rem",
    },
    subtitle1: {
      fontWeight: "bold",
    },
    body2: {
      lineHeight: 1.3,
    },
  },
  components: {
    MuiCardActions: {
      styleOverrides: {
        root: {
          "&>:not(:first-of-type)": {
            margin: 0,
          },
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        size: "small",
        fullWidth: true,
      },
    },
    MuiTextField: {
      defaultProps: {
        size: "small",
        fullWidth: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          "&.small": {
            fontSize: "0.8rem",
          },
        },
      },
    },
  },
};
