declare global {
  interface Number {
    formatNumber(decimals?: number): String;
  }
}

// eslint-disable-next-line no-extend-native
Number.prototype.formatNumber = function (decimals: number = 2) {
  if (!Number(this)) return Number(this).toString();

  return Number(this).toLocaleString(navigator.language, {
    maximumFractionDigits: decimals,
    minimumFractionDigits: decimals,
  });
};

export {};
