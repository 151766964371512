import { Role, Type } from "api/models";
import React from "react";
import { Route } from "./route.model";

const Home = React.lazy(() => import("../../features/Home"));
const Assortment = React.lazy(
  () => import("../../features/assortment/Assortment")
);
const ProductDetail = React.lazy(
  () => import("../../features/assortment/ProductDetail")
);
const ShoppingCart = React.lazy(
  () => import("../../features/cart/ShoppingCart")
);
const MyProfile = React.lazy(() => import("../../features/user/MyProfile"));
const Favorites = React.lazy(() => import("../../features/favorite/Favorites"));
const OrderConfirmation = React.lazy(
  () => import("../../features/order/OrderConfirmation")
);
const Unauthorized = React.lazy(
  () => import("../../features/user/Unauthorized")
);

export const Routes: Route[] = [
  { path: "/", name: "Home", Component: Home, hideBreadCrumbs: true },
  {
    path: "/products",
    name: "Products",
    Component: Assortment,
    componentProps: { type: Type.Paint },
  },
  {
    path: "/products/:supplierId/:supplierArticleNumber",
    name: "Details",
    Component: ProductDetail,
    returnUrl: "/products",
  },
  {
    path: "/accessories",
    name: "Accessories",
    Component: Assortment,
    componentProps: { type: Type.NonPaint },
    isPrivateRoute: true,
    requiredRoles: [Role.Administrator],
  },
  {
    path: "/accessories/:supplierId/:supplierArticleNumber",
    name: "Details",
    Component: ProductDetail,
    isPrivateRoute: true,
    requiredRoles: [Role.Administrator],
  },
  {
    path: "/search",
    name: "Search",
    Component: Assortment,
    componentProps: { keepFilter: true },
  },
  {
    path: "/cart",
    name: "Cart",
    Component: ShoppingCart,
    isPrivateRoute: true,
    requiredRoles: [Role.Administrator],
  },
  {
    path: "/profile",
    name: "Profile",
    Component: MyProfile,
    isPrivateRoute: true,
    requiredRoles: [Role.Administrator],
  },
  {
    path: "/profile/orderhistory",
    name: "OrderHistory",
    Component: MyProfile,
    isPrivateRoute: true,
    requiredRoles: [Role.Administrator],
  },
  {
    path: "/favorites",
    name: "Favorites",
    Component: Favorites,
    isPrivateRoute: true,
    requiredRoles: [Role.Administrator],
  },
  {
    path: "/orderconfirmation",
    name: "Cart",
    Component: OrderConfirmation,
    isPrivateRoute: true,
    requiredRoles: [Role.Administrator],
  },
  {
    path: "/unauthorized",
    name: "Unauthorized",
    Component: Unauthorized,
    hideBreadCrumbs: true,
    returnUrl: "/",
  },
];
