import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { Country, Language } from "api/models";
import { i18nService } from "api/services";
import { RootState } from "app";

export interface I18nState {
  translations: Record<string, string> | null;
  languages: Language[];
  countries: Country[];
}

const initialState: I18nState = {
  translations: null,
  languages: [],
  countries: [],
};

export const getTranslations = createAsyncThunk(
  "i18n/translations",
  async (languageCode: string, thunkAPI) => {
    return await i18nService.getTranslations(languageCode);
  }
);

export const getLanguages = createAsyncThunk(
  "i18n/languages",
  async (arg, thunkAPI) => {
    return await i18nService.getLanguages();
  }
);

export const getCountries = createAsyncThunk(
  "i18n/countries",
  async (arg, thunkAPI) => {
    return await i18nService.getCountries();
  }
);

const i18nSlice = createSlice({
  name: "i18n",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getTranslations.fulfilled,
      (state, action: PayloadAction<Record<string, string>>) => {
        state.translations = action.payload;
      }
    );
    builder.addCase(
      getLanguages.fulfilled,
      (state, action: PayloadAction<Language[]>) => {
        state.languages = action.payload;
      }
    );
    builder.addCase(
      getCountries.fulfilled,
      (state, action: PayloadAction<Country[]>) => {
        state.countries = action.payload;
      }
    );
  },
});

export const selectT9n = createDraftSafeSelector(
  (state: RootState) => state.i18n,
  (i18n) => i18n.translations
);

export default i18nSlice.reducer;
