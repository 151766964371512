import { RootState, useAppDispatch, useAppSelector } from "app";
import { getBrand, getPrivacyTermsUrls } from "features/configuration";
import { localeService } from "features/locale";
import React, { useEffect } from "react";
import { shallowEqual } from "react-redux";
import { cookieService } from "services";

const ConfigSuspense = ({ children }: React.PropsWithChildren<{}>) => {
  const brand = useAppSelector((state: RootState) => state.config.brand);
  const user = useAppSelector(
    (state: RootState) => state.user.profile,
    shallowEqual
  );
  const i18n = localeService.getI18nFromUrl();

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getBrand());
  }, [dispatch]);

  useEffect(() => {
    if (user || i18n) dispatch(getPrivacyTermsUrls(i18n));
  }, [dispatch, user, i18n]);

  if (!brand) return <></>;

  //first API call is made, versionCookie is set
  updateDomHeader(brand);

  return <>{children}</>;
};

const updateDomHeader = (brand: string) => {
  document.title = `${brand} - Marketplace`;

  var version = cookieService.get("marketplace_version");
  if (version) upsertMetaTag("version", version);
};

const upsertMetaTag = (name: any, content: string) => {
  var meta = document.getElementsByTagName("meta")[name];
  if (meta) meta.content = content;
};

export default ConfigSuspense;
