export interface UserProfile {
  role: Role;
  userName?: string;
  country?: string;
  language?: string;
  currencyCode?: string;
}

export enum Role {
  Administrator = 0,
}
