import { Box, Typography } from "@mui/material";
import { RootState, useAppSelector } from "app";
import React from "react";
import { Link } from "react-router-dom";
import { selectT9n } from "features/locale";

const Footer = () => {
  const t9n = useAppSelector(selectT9n);
  const terms = useAppSelector((state: RootState) => state.config.termsUrl);
  const privacy = useAppSelector((state: RootState) => state.config.privacyUrl);

  if (!terms && !privacy) return <></>;

  return (
    <Box id="footer" display="flex" justifyContent="center" pb={1}>
      {terms && (
        <Typography
          variant="body2"
          color="secondary"
          component={Link}
          to={{
            pathname: terms,
          }}
          target="_blank"
        >
          {t9n?.TermsOfService}
        </Typography>
      )}

      {terms && privacy && (
        <Typography variant="body2" color="secondary" px={1 / 2}>
          -
        </Typography>
      )}

      {privacy && (
        <Typography
          variant="body2"
          color="secondary"
          component={Link}
          to={{
            pathname: privacy,
          }}
          target="_blank"
        >
          {t9n?.PrivacyStatement}
        </Typography>
      )}
    </Box>
  );
};

export default Footer;
