import { I18N } from "api/models";
import { RootState, useAppDispatch, useAppSelector } from "app";
import { getUserProfile } from "features/user";
import {
  getCountries,
  getLanguages,
  selectT9n,
  getTranslations,
  localeService,
} from "features/locale";
import React, { useEffect } from "react";
import { shallowEqual } from "react-redux";
import { cookieKeys, cookieService } from "services";

const Localization = ({ children }: React.PropsWithChildren<{}>) => {
  const fallbackLanguage = "en";
  const user = useAppSelector(
    (state: RootState) => state.user.profile,
    shallowEqual
  );
  const t9n = useAppSelector(selectT9n);

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getLanguages());
    dispatch(getCountries());
    dispatch(getUserProfile());
  }, [dispatch]);
  let i18n: I18N | null;

  if (user && user.language && user.country) {
    //validity will be checked in LocalizedRouter
    i18n = { lang: user.language, country: user.country };
  } else {
    i18n = localeService.getI18nFromUrl();
  }
  if (i18n) {
    cookieService.set(cookieKeys.i18n, JSON.stringify(i18n));
  }

  useEffect(() => {
    if (!t9n)
      dispatch(
        getTranslations(
          i18n?.lang ??
            localeService.getI18nFromBrowser().lang ??
            fallbackLanguage
        )
      );
  }, [dispatch, i18n, t9n]);

  return <>{children}</>;
};

export default Localization;
