import {
  CreateOrder,
  CreateOrderResponse,
  Order,
  OrderList,
  OrderSearch,
} from "api/models";
import utils from "api/Utils";

const baseUrl = "api/order";

const get = async (orderSearch: OrderSearch) => {
  return await utils.apiGet<OrderList>(baseUrl, orderSearch);
};

const getById = async (id: number) => {
  return await utils.apiGet<Order>(`${baseUrl}/${id}`);
};

const createOrder = async (order: CreateOrder) => {
  return await utils.apiCommand<CreateOrder, CreateOrderResponse>(
    baseUrl,
    order
  );
};

const resendOrder = async (id: number) => {
  return await utils.apiCommand(`${baseUrl}/Resend/${id}`, null);
};

const orderService = { get, getById, createOrder, resendOrder };
export default orderService;
