import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  Assortment,
  AssortmentFilter,
  AssortmentSearch,
  AvailableFilter,
  Favorite,
  Product,
  ProductDetailFilter,
  SortColumn,
  Type,
  Variant,
} from "api/models";
import { assortmentService } from "api/services";
import { RootState } from "app";
export interface AssortmentState {
  list: Product[];
  filters: AvailableFilter;
  totalCount: number;
  currentFilter: AssortmentSearch;
  message?: string;
}

const initialCurrentFilterState: AssortmentSearch = {
  skip: 0,
  take: 30,
  sortColumn: SortColumn.Name,
  sortAscending: true,
  refresh: true,
};
const initialFiltersState: AvailableFilter = {
  categories: [],
  subCategories: [],
  manufacturers: [],
};
const initialState: AssortmentState = {
  filters: initialFiltersState,
  list: [],
  totalCount: 0,
  currentFilter: initialCurrentFilterState,
};

export const getAnonymousAssortment = createAsyncThunk(
  "anonymousAssortment/get",
  async ({ filter, i18n }: AssortmentFilter, thunkAPI) => {
    return await assortmentService.getAnonymous(filter, i18n);
  }
);

export const getAssortment = createAsyncThunk<
  { assortment: Assortment; favorites: Favorite[] },
  AssortmentFilter,
  { state: RootState }
>(
  "assortment/get",
  async ({ filter, i18n }: AssortmentFilter, { getState }) => {
    return {
      assortment: await assortmentService.get(filter, i18n),
      favorites: getState().favorite.list,
    };
  }
);

export const getProductDetail = createAsyncThunk(
  "assortment/getProductDetail",
  async ({ supplierId, key, i18n }: ProductDetailFilter, thunkAPI) => {
    return {
      productDetail:
        supplierId === 0
          ? await assortmentService.getAnonymousByKey(key, i18n)
          : await assortmentService.getByKey(supplierId, key, i18n),
      key,
    };
  }
);

const assortmentSlice = createSlice({
  name: "assortment",
  initialState,
  reducers: {
    setSelectedVariant: (
      state,
      action: PayloadAction<{
        productKey: string;
        supplierArticleNumber: string;
      }>
    ) => {
      const index = state.list.findIndex(
        (p) => p.productKey === action.payload.productKey
      );
      if (index > -1) {
        state.list[index].selectedVariantSupplierArticleNumber =
          action.payload.supplierArticleNumber;
      }
    },
    setType: (state, action: PayloadAction<Type | undefined>) => {
      state.currentFilter = {
        ...initialCurrentFilterState,
        type: action.payload,
        refresh: false,
      };
      if (action.payload) state.currentFilter.freeText = undefined; //browsing to product/accessories page, clear searchfield
    },
    setSearchField: (state, action: PayloadAction<string>) => {
      state.currentFilter = {
        ...initialCurrentFilterState,
        freeText: action.payload,
        refresh: false,
      };
    },
    setCurrentFilter: (state, action: PayloadAction<AssortmentSearch>) => {
      state.currentFilter = {
        ...action.payload,
        refresh: false,
      };
    },
    clearCurrentFilter: (state) => {
      state.currentFilter = initialCurrentFilterState;
    },
    clear: (state) => {
      state.list = initialState.list;
      state.filters = initialState.filters;
      state.totalCount = initialState.totalCount;
      state.currentFilter = initialCurrentFilterState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getAssortment.fulfilled,
      (
        state,
        action: PayloadAction<{ assortment: Assortment; favorites: Favorite[] }>
      ) => {
        const { list, filters, totalCount, message } =
          action.payload.assortment;

        const searchValue = state.currentFilter.freeText?.toLowerCase() ?? "";
        const favorites = action.payload.favorites.filter(
          (f) =>
            f.articleNumber?.toLowerCase().includes(searchValue) ||
            f.supplierArticleNumber?.toLowerCase().includes(searchValue) ||
            f.name?.toLowerCase().includes(searchValue) ||
            f.selector?.toLowerCase().includes(searchValue)
        );

        state.list = list ?? [];

        state.list.map((p) => {
          let selectedVariant: Favorite | Variant | undefined = favorites.find(
            (f) =>
              f.supplierId === p.supplierId &&
              p.variants.some(
                (v) => v.supplierArticleNumber === f.supplierArticleNumber
              )
          );

          if (!selectedVariant)
            selectedVariant = p.variants.find(
              (v) =>
                v.articleNumber?.toLowerCase().includes(searchValue) ||
                v.supplierArticleNumber?.toLowerCase().includes(searchValue) ||
                v.selector?.toLowerCase().includes(searchValue)
            );

          p.selectedVariantSupplierArticleNumber = selectedVariant
            ? selectedVariant.supplierArticleNumber
            : p.variants[0].supplierArticleNumber;

          return p;
        });

        state.filters = filters ?? initialFiltersState;
        state.totalCount = totalCount;
        state.message = message;
      }
    );

    builder.addCase(
      getAnonymousAssortment.fulfilled,
      (state, action: PayloadAction<Assortment>) => {
        state.list = action.payload.list ?? [];
        state.list.map(
          (p) =>
            (p.selectedVariantSupplierArticleNumber =
              p.variants[0].supplierArticleNumber)
        );
        state.filters = action.payload.filters ?? initialFiltersState;
        state.totalCount = action.payload.totalCount;
        state.message = action.payload.message;
      }
    );

    builder.addCase(
      getProductDetail.fulfilled,
      (
        state,
        action: PayloadAction<{
          productDetail: Product;
          key: string;
        }>
      ) => {
        const index = state.list.findIndex(
          (p) => p.productKey === action.payload.productDetail.productKey
        );
        if (index > -1) {
          state.list[index] = action.payload.productDetail;
          state.list[index].selectedVariantSupplierArticleNumber =
            action.payload.key;
        } else {
          state.list.push(action.payload.productDetail);
          state.list[
            state.list.length - 1
          ].selectedVariantSupplierArticleNumber = action.payload.key;
        }
      }
    );
  },
});

export const selectMemoizedProduct = (id: number) =>
  createDraftSafeSelector(
    (state: RootState) => state.assortment.list,
    (products) => products.find((p) => p.id === id)
  );

export const selectMemoizedProductBySupplierArticleNumber = (
  supplierArticleNumber: string
) =>
  createDraftSafeSelector(
    (state: RootState) => state.assortment.list,
    (products) =>
      products.find(
        (p) => p.selectedVariantSupplierArticleNumber === supplierArticleNumber
      )
  );

export const {
  setSelectedVariant,
  setCurrentFilter,
  clearCurrentFilter,
  clear,
  setType,
  setSearchField,
} = assortmentSlice.actions;
export default assortmentSlice.reducer;
