import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { Favorite, SetFavorite, SortColumn } from "api/models";
import { favoriteService } from "api/services";
import { RootState } from "app";
import { setMessage } from "features/message";
import { sortService } from "services";

enum ExtraSortColumn {
  DateAdded = "DateAdded",
}
export const FavoriteSortColumn = {
  ...ExtraSortColumn,
  ...SortColumn,
};

export type FavoriteSortColumnType = SortColumn | ExtraSortColumn;

interface FavoriteState {
  searchField?: string;
  orderBy: {
    column: FavoriteSortColumnType;
    sortAscending?: boolean;
  };
  list: Favorite[];
}

const initialState: FavoriteState = {
  orderBy: {
    column: FavoriteSortColumn.DateAdded,
    sortAscending: true,
  },
  list: [],
};

export const getFavorites = createAsyncThunk(
  "get/favorites",
  async (arg, thunkAPI) => {
    return await favoriteService.getFavorites();
  }
);

export const addFavorite = createAsyncThunk(
  "add/favorite",
  async (favorite: SetFavorite, { dispatch, rejectWithValue }) => {
    try {
      await favoriteService.addFavorite(favorite);
      return favorite;
    } catch {
      dispatch(setMessage({ message: "FavoriteError", status: "error" }));
      return rejectWithValue(null);
    }
  }
);

export const deleteFavorite = createAsyncThunk(
  "delete/favorite",
  async (favorite: SetFavorite, { dispatch, rejectWithValue }) => {
    try {
      await favoriteService.deleteFavorite(favorite);
      return favorite;
    } catch {
      dispatch(setMessage({ message: "FavoriteError", status: "error" }));
      return rejectWithValue(null);
    }
  }
);

const favoriteSlice = createSlice({
  name: "favorite",
  initialState,
  reducers: {
    setFavoriteSearchField: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.searchField = action.payload;
    },
    setFavoriteOrderBy: (
      state,
      action: PayloadAction<{
        column: FavoriteSortColumnType;
        sortAscending?: boolean;
      }>
    ) => {
      state.orderBy = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getFavorites.fulfilled,
      (state, action: PayloadAction<Favorite[]>) => {
        state.list = action.payload;
      }
    );
    builder.addCase(
      addFavorite.fulfilled,
      (state, action: PayloadAction<SetFavorite>) => {
        const favorite = state.list.find(
          (c) =>
            c.supplierArticleNumber === action.payload.supplierArticleNumber &&
            c.supplierId === action.payload.supplierId
        );
        if (!favorite) state.list.push({ ...action.payload });
      }
    );
    builder.addCase(
      deleteFavorite.fulfilled,
      (state, action: PayloadAction<SetFavorite>) => {
        const index = state.list.findIndex(
          (c) =>
            c.supplierArticleNumber === action.payload.supplierArticleNumber &&
            c.supplierId === action.payload.supplierId
        );
        if (index > -1) state.list.splice(index, 1);
      }
    );
  },
});

export const selectMemoizedFavoritesList = createDraftSafeSelector(
  (state: RootState) => state.favorite.list,
  (list) =>
    list.map((f) => ({
      supplierId: f.supplierId,
      supplierArticleNumber: f.supplierArticleNumber,
    }))
);

export const selectMemoizedFilteredFavoritesList = createDraftSafeSelector(
  (state: RootState) => state.favorite,
  (state) => {
    const searchValue = state.searchField?.toLowerCase() ?? "";
    return state.list
      .filter(
        (f) =>
          f.articleNumber?.toLowerCase().includes(searchValue) ||
          f.supplierArticleNumber?.toLowerCase().includes(searchValue) ||
          f.name?.toLowerCase().includes(searchValue) ||
          f.selector?.toLowerCase().includes(searchValue)
      )
      .sort(
        sortService.sortByCI(state.orderBy.column, state.orderBy.sortAscending)
      )
      .map((f) => f.id);
  }
);

export const selectMemoizedFavorite = (id: number) =>
  createDraftSafeSelector(
    (state: RootState) => state.favorite.list,
    (favorites) => favorites.find((f) => f.id === id)
  );

export const { setFavoriteSearchField, setFavoriteOrderBy } =
  favoriteSlice.actions;
export default favoriteSlice.reducer;
