import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";
import { RootState, useAppDispatch, useAppSelector } from "app";
import { caretIcon } from "assets";
import { clearCurrentFilter } from "features/assortment";
import { clearPrivacyTermsUrls } from "features/configuration";
import { selectT9n, localeService } from "features/locale";
import { setLanguage } from "features/user";
import React, { useState } from "react";
import { shallowEqual } from "react-redux";

interface FooterProps {
  openLocaleDialog: (isOpen: boolean) => void;
}

const LocalizationSelection = ({ openLocaleDialog }: FooterProps) => {
  const [menuAnchorEl, setmenuAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuAnchorEl);

  const isAuthenticated = useAppSelector(
    (state: RootState) => state.user.isAuthenticated
  );
  const t9n = useAppSelector(selectT9n);
  const countries = useAppSelector(
    (state: RootState) => state.i18n.countries,
    shallowEqual
  );
  const languages = useAppSelector(
    (state: RootState) => state.i18n.languages,
    shallowEqual
  );
  const i18n = localeService.getI18nFromUrl();

  const dispatch = useAppDispatch();

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setmenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (language?: string) => {
    setmenuAnchorEl(null);
    if (language) {
      dispatch(setLanguage(language));
      dispatch(clearCurrentFilter());
      dispatch(clearPrivacyTermsUrls());
    }
  };

  const handleOpenLocaleDialog = () => {
    openLocaleDialog(true);
  };

  if (!i18n || !t9n || languages.length <= 0 || countries.length <= 0)
    return <></>;

  return (
    <Box>
      <Button
        onClick={isAuthenticated ? handleMenuClick : handleOpenLocaleDialog}
        sx={{ p: 0, pt: 1, justifyContent: "flex-start" }}
      >
        <Typography variant="subtitle2" color="secondary" sx={{ pr: 1 }}>
          {!isAuthenticated &&
            t9n[`Country.${i18n?.country?.toLocaleUpperCase()}`] + " | "}
          {
            languages.find(
              (l) => l.code.toLowerCase() === i18n?.lang?.toLowerCase()
            )?.nativeName
          }
        </Typography>
        {isAuthenticated && (
          <img
            src={caretIcon}
            alt="caret"
            className={menuOpen ? "turnImg" : ""}
          />
        )}
      </Button>
      <Menu
        anchorEl={menuAnchorEl}
        open={menuOpen}
        onClose={() => handleMenuClose()}
      >
        {languages.length > 0
          ? languages.map((l) => (
              <MenuItem key={l.code} onClick={() => handleMenuClose(l.code)}>
                {l.nativeName}
              </MenuItem>
            ))
          : null}
      </Menu>
    </Box>
  );
};

export default LocalizationSelection;
