import { ThemeProvider } from "@mui/material";
import { GlobalStyles } from "@mui/styled-engine";
import { RootState, useAppSelector } from "app";
import React from "react";
import { loadFavicon } from "./Mixins";

import { getStylesPathByBrand, getThemeByBrand } from "./ThemeSelector";

const CustomThemeProvider = (props: React.PropsWithChildren<{}>) => {
  const brand = useAppSelector((root: RootState) => root.config.brand);

  if (!brand) return <></>;

  const theme = getThemeByBrand(brand);
  const styles = getStylesPathByBrand(brand);

  loadFavicon(`/brands/${brand.toLowerCase()}/favicon.ico`);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles styles={styles} />
      {props.children}
    </ThemeProvider>
  );
};

export default CustomThemeProvider;
