import "./extensions";

import { CssBaseline } from "@mui/material";
import store from "app/Store";
import ConfigSuspense from "features/configuration/ConfigSuspense";
import Localization from "features/locale/Localization";
import LocalizedRouter from "features/locale/LocalizedRouter";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorkerRegistration from "serviceWorkerRegistration";
import reportWebVitals from 'reportWebVitals';
import CustomThemeProvider from "themes/CustomThemeProvider";

const rootElement = document.getElementById("root");

const render = () => {
  const App = require("./app/App").default;
  ReactDOM.render(
    <Provider store={store}>
      <ConfigSuspense>
        <CustomThemeProvider>
          <CssBaseline />
          <Localization>
            <LocalizedRouter RouterComponent={BrowserRouter}>
              <App />
            </LocalizedRouter>
          </Localization>
        </CustomThemeProvider>
      </ConfigSuspense>
    </Provider>,
    rootElement
  );
};

render();

if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./app/App", render);
}

serviceWorkerRegistration.register();
reportWebVitals();
