import {
  CartItem,
  UpsertCartItem,
  UploadStatus,
  CsvProcessResult,
} from "api/models";
import utils from "api/Utils";

const baseUrl = "api/cart";

const getCartItems = async () => {
  return await utils.apiGet<CartItem[]>(baseUrl);
};

const addToCart = async (cartItem: UpsertCartItem) => {
  return await utils.apiCommand(baseUrl, cartItem);
};

const updateCartItem = async (cartItem: UpsertCartItem) => {
  return await utils.apiCommand(baseUrl, cartItem, "PUT");
};

const deleteNonAssortemtCartItems = async () => {
  return await utils.apiCommand(baseUrl, null, "DELETE");
};

const downloadCartTemplate = async () => {
  return await utils.apiDownload(`${baseUrl}/xlsxTemplate`, null);
};

const uploadCart = async (
  file: Blob | FileList,
  onUploadProgress: (
    progress: number,
    status: UploadStatus,
    result?: CsvProcessResult
  ) => void
) => {
  return utils.apiUpload(`${baseUrl}/upload`, file, onUploadProgress);
};

const cartService = {
  getCartItems,
  addToCart,
  updateCartItem,
  deleteNonAssortemtCartItems,
  downloadCartTemplate,
  uploadCart,
};
export default cartService;