import React from "react";
import { Route, RouteProps, useHistory, useLocation } from "react-router";
import { Role } from "api/models";
import { RootState, useAppSelector } from "app";
import { shallowEqual } from "react-redux";
import { authService } from "api/services";

interface PrivateRouteProps extends RouteProps {
  requiredRoles?: Role[];
}

const PrivateRoute = ({
  component: Component,
  requiredRoles,
  ...rest
}: PrivateRouteProps) => {
  const isAuthenticated = useAppSelector(
    (state: RootState) => state.user.isAuthenticated
  );
  const user = useAppSelector(
    (state: RootState) => state.user.profile,
    shallowEqual
  );
  const returnUrl = useAppSelector(
    (state: RootState) => state.config.returnUrl
  );
  const { pathname } = useLocation();
  const history = useHistory();

  if (isAuthenticated === undefined) return <></>;
  if (!isAuthenticated) authService.login(returnUrl ?? pathname);

  if (!user || !requiredRoles?.includes(user.role))
    history.push("/unauthorized");

  return <Route {...rest}></Route>;
};

export default PrivateRoute;
