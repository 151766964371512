import { localeService } from "features/locale";
const login = (returnUrl?: string) => {
  const controllerUrl = `/auth/login${
    returnUrl
      ? `?returnUrl=${returnUrl.replace(localeService.getI18nBaseUrl(), "")}`
      : ""
  }`;
  document.location.href = document.location.origin + controllerUrl;
};

const authService = { login };
export default authService;
