import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface MessageState {
  status?: "success" | "error" | "warning";
  message?: string;
  timeOut?: number;
}

const initialState: MessageState = {};

const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    setMessage: (state, action: PayloadAction<MessageState>) => {
      state.message = action.payload.message;
      state.status = action.payload.status;
      state.timeOut = action.payload.timeOut;
    },
    clearMessage: (state) => {
      state.message = initialState.message;
      state.status = initialState.status;
      state.timeOut = initialState.timeOut;
    },
  },
});

export const { setMessage, clearMessage } = messageSlice.actions;
export default messageSlice.reducer;
