import { Alert } from "@mui/material";
import { RootState, useAppDispatch, useAppSelector } from "app";
import { selectT9n } from "features/locale";
import { clearMessage } from "features/message";
import React, { useEffect } from "react";
import { shallowEqual } from "react-redux";

let timeOutId: any;
const Message = () => {
  const t9n = useAppSelector(selectT9n);
  const { message, status, timeOut } = useAppSelector(
    (state: RootState) => state.message,
    shallowEqual
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (timeOut)
      timeOutId = setTimeout(() => dispatch(clearMessage()), timeOut);
    return () => {
      if (timeOutId) clearTimeout(timeOutId);
    };
  }, [dispatch, timeOut]);
  return (
    <>
      {message ? (
        <>
          <Alert
            severity={status}
            onClick={() => dispatch(clearMessage())}
            onClose={() => dispatch(clearMessage())}
            sx={{ my: 2, cursor: "pointer" }}
          >
            {t9n?.[message] ?? message}
          </Alert>
        </>
      ) : null}
    </>
  );
};

export default Message;
