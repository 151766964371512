import { cookieService, cookieKeys } from "services";
import { Language, Country, I18N } from "api/models";

const langRegex = /^[a-zA-Z]{2,3}$/;
const countryRegex = /^[a-zA-Z]{2}$/;

const getI18nFromUrl = (): I18N | null => {
  // /country/language/rest of path
  const urlSegments = window.location.pathname.split("/");

  if (checkIfValidI18nUrl(urlSegments))
    return { country: urlSegments[1], lang: urlSegments[2] };

  return null;
};

const getI18nBaseUrl = (): string => {
  const i18n = getI18nFromUrl();
  return i18n ? `/${i18n.country}/${i18n.lang}` : "/";
};

const getUpdatedI18nUrl = (newLanguage: string) => {
  const i18n = getI18nFromUrl();
  const newI18nUrl = `/${i18n?.country}/${
    newLanguage && langRegex.test(newLanguage) ? newLanguage : i18n?.lang
  }`;
  return correctI18nUrl(newI18nUrl);
};

const correctI18nUrl = (newI18nUrl: string) => {
  const oldI18nUrl = getI18nBaseUrl();
  if (checkIfValidI18nUrl(newI18nUrl))
    return oldI18nUrl === "/"
      ? `${newI18nUrl}${window.location.pathname}`
      : window.location.pathname.replace(oldI18nUrl, newI18nUrl);

  return window.location.pathname;
};

const checkIfValidI18nUrl = (url: string | string[]) => {
  let urlSegments = url;
  if (typeof url === "string") urlSegments = url.split("/");
  return (
    urlSegments.length >= 3 &&
    countryRegex.test(urlSegments[1]) &&
    langRegex.test(urlSegments[2])
  );
};

const isValid = (
  availableList: Country[] | Language[],
  key?: string | null
): boolean => {
  return availableList.some(
    (x: Country | Language) => x.code.toLowerCase() === key?.toLowerCase()
  );
};

const getI18nFromBrowser = (): I18N => {
  const i18nCookie = cookieService.get(cookieKeys.i18n);
  if (i18nCookie) {
    const i18n: I18N = JSON.parse(i18nCookie);
    if (i18n) return i18n;
  }

  const localeSegments = navigator.language.split(/\W/);
  return {
    lang: localeSegments[0],
    country: localeSegments.length > 1 ? localeSegments[1] : undefined,
  };
};

const localeService = {
  getI18nBaseUrl,
  getI18nFromUrl,
  getUpdatedI18nUrl,
  getI18nFromBrowser,
  checkIfValidI18nUrl,
  correctI18nUrl,
  isValid,
};
export default localeService;
