import "./Nav.scss";

import {
  AppBar,
  Badge,
  Box,
  Button,
  Divider,
  Drawer,
  FormGroup,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  OutlinedInput,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Role } from "api/models";
import { authService } from "api/services";
import { RootState, useAppDispatch, useAppSelector } from "app";
import {
  accountIcon,
  cartIcon,
  favoriteIcon,
  menuIcon,
  searchIcon,
} from "assets";
import {clearCurrentFilter, setSearchField} from "features/assortment";
import { selectCartItemTotal } from "features/cart";
import { selectT9n, localeService, LocalizedNavLink } from "features/locale";
import React, { useEffect, useState } from "react";
import { shallowEqual } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

const NavMenu = () => {
  const [searchFieldText, setSearchFieldText] = useState<string>("");

  const isAuthenticated = useAppSelector(
    (state: RootState) => state.user.isAuthenticated
  );
  const user = useAppSelector(
    (state: RootState) => state.user.profile,
    shallowEqual
  );
  const returnUrl = useAppSelector(
    (state: RootState) => state.config.returnUrl
  );

  const cartCount = useAppSelector(selectCartItemTotal);
  const t9n = useAppSelector(selectT9n);

  const history = useHistory();
  const dispatch = useAppDispatch();
  const dispatchSearchField = (value: string) => {
    if (value) {
      history.push(`${localeService.getI18nBaseUrl()}/search`);
      dispatch(setSearchField(value));
    }else{
      history.push(`${localeService.getI18nBaseUrl()}/products`);
      clearCurrentFilter();
    }
  };

  useEffect(() => {
    if (searchFieldText) setSearchFieldText(searchFieldText);
  }, [searchFieldText]);
  const initialSearchText = useAppSelector(
    (state: RootState) => state.assortment.currentFilter.freeText
  );
  useEffect(() => {
    setSearchFieldText(initialSearchText ?? "");
  }, [initialSearchText]);

  const [menuAnchorEl, setmenuAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuAnchorEl);
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setmenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setmenuAnchorEl(null);
  };

  const [drawerOpen, setdrawerOpen] = useState(false);
  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setdrawerOpen(open);
    };

  const handleInputChange = (event: any) => {
    setSearchFieldText(event.target.value);
  };
  const handleEnterPress = (event: any) => {
    if (event.charCode === 13) dispatchSearchField(searchFieldText);
  };

  const { pathname } = useLocation();
  const handleLogin = () => {
    authService.login(returnUrl ?? pathname);
  };
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const isMediumScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  return (
    <AppBar id="nav" position="static" color="inherit">
      <Toolbar
        disableGutters
        sx={{
          display: "grid",
          justifyContent: "space-between",
          gridTemplateAreas: isSmallScreen
            ? `"logo . icon-menu"
                  "search search search"`
            : `"logo . search search"
                  "menu . . icon-menu"`,
          pt: 1 / 2,
        }}
      >
        <Box sx={{ gridArea: "logo", display: "flex", alignItems: "center" }}>
          {isSmallScreen ? (
            <>
              <IconButton
                size="large"
                edge="start"
                onClick={toggleDrawer(true)}
              >
                <img src={menuIcon} alt="menu"></img>
              </IconButton>
              <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
                id="side-nav"
              >
                <Box sx={{ p: 3 }}>
                  <MenuItems
                    isAuthenticated={
                      isAuthenticated && user?.role === Role.Administrator
                    }
                    isSmallScreen={isSmallScreen}
                    t9n={t9n}
                    onItemClicked={toggleDrawer(false)}
                  />
                </Box>
              </Drawer>
            </>
          ) : null}

          <LocalizedNavLink to="/">
            <Typography id="brand-logo" component="div" />
          </LocalizedNavLink>
        </Box>

        {!isSmallScreen ? (
          <Box sx={{ gridArea: "menu" }}>
            <MenuItems
              isAuthenticated={
                isAuthenticated && user?.role === Role.Administrator
              }
              isSmallScreen={isSmallScreen}
              t9n={t9n}
            />
          </Box>
        ) : null}

        <Box
          sx={{
            gridArea: "icon-menu",
            display: "flex",
            justifyContent: "end",
            py: 1,
          }}
        >
          {!isAuthenticated ? (
            <Button
              onClick={handleLogin}
              sx={{
                display: pathname.toLowerCase().includes("unauthorized")
                  ? "none"
                  : "block",
              }}
            >
              {t9n?.Login}
            </Button>
          ) : (
            <>
              {!isSmallScreen ? (
                <Box>
                  <Typography
                    variant="subtitle2"
                    color="primary"
                    sx={{ textAlign: "end", fontSize: "0.8rem" }}
                  >
                    {t9n?.Welcome}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="primary"
                    sx={{ fontSize: "0.8rem", lineHeight: "1rem" }}
                  >
                    {isMediumScreen &&
                    user?.userName &&
                    user.userName.length > 12
                      ? user?.userName?.substring(0, 10) + "..."
                      : user?.userName}
                  </Typography>
                </Box>
              ) : null}

              <IconButton
                onClick={handleMenuClick}
                sx={{ p: isSmallScreen ? 1 / 2 : 1 }}
              >
                <img src={accountIcon} alt="account" />
              </IconButton>
              <Menu
                anchorEl={menuAnchorEl}
                open={menuOpen}
                onClose={handleMenuClose}
              >
                <LocalizedNavLink exact to="/profile" activeClassName="active">
                  <MenuItem onClick={handleMenuClose}>
                    <ListItemText color="primary">{t9n?.Profile}</ListItemText>
                  </MenuItem>
                </LocalizedNavLink>
                <MenuItem onClick={handleMenuClose}>
                  <form
                    id="logout-form"
                    className="form-inline"
                    action="auth/logout"
                    method="post"
                  >
                    <ListItemText
                      color="primary"
                      onClick={() =>
                        (
                          document.getElementById(
                            "logout-form"
                          ) as HTMLFormElement
                        ).submit()
                      }
                    >
                      {t9n?.Logout}
                    </ListItemText>
                  </form>
                </MenuItem>
              </Menu>
              <IconButton sx={{ p: isSmallScreen ? 1 / 2 : 1 }}>
                <LocalizedNavLink
                  exact
                  to="/favorites"
                  activeClassName="active"
                >
                  <img src={favoriteIcon} alt="favorites" />
                </LocalizedNavLink>
              </IconButton>
              <IconButton sx={{ p: isSmallScreen ? 1 / 2 : 1 }}>
                <LocalizedNavLink exact to="/cart" activeClassName="active">
                  <Badge badgeContent={cartCount} color="primary">
                    <img src={cartIcon} alt="cart" />
                  </Badge>
                </LocalizedNavLink>
              </IconButton>
            </>
          )}
        </Box>

        <FormGroup
          row
          sx={{ gridArea: "search", width: 1, pb: isSmallScreen ? 1 : 0 }}
        >
          <OutlinedInput
            size="small"
            placeholder={t9n?.SearchCatalog}
            sx={{ width: 1 }}
            onChange={handleInputChange}
            onKeyPress={handleEnterPress}
            value={searchFieldText}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={() => dispatchSearchField(searchFieldText)}
                  sx={{ p: 0 }}
                >
                  <img src={searchIcon} alt="search" width={20} />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormGroup>
      </Toolbar>
    </AppBar>
  );
};

interface MenuItemsProps {
  isSmallScreen: boolean;
  t9n: Record<string, string> | null;
  isAuthenticated?: boolean;
  onItemClicked?: (e: React.MouseEvent) => void;
}
const MenuItems = ({
  isSmallScreen,
  t9n,
  isAuthenticated,
  onItemClicked,
}: MenuItemsProps) => {
  return (
    <List
      sx={{
        display: isSmallScreen ? "block" : "flex",
        pb: 0,
      }}
      dense
    >
      {isSmallScreen ? (
        <>
          <ListItem>
            <ListItemText>
              {" "}
              <Typography variant="subtitle1">{t9n?.Menu}</Typography>
            </ListItemText>
          </ListItem>
          <Divider />
        </>
      ) : null}
      <ListItem sx={{ pl: isSmallScreen ? 2 : 0 }}>
        <ListItemText>
          <LocalizedNavLink
            exact
            to="/"
            activeClassName="active"
            onClick={onItemClicked}
          >
            {t9n?.Home}
          </LocalizedNavLink>
        </ListItemText>
      </ListItem>
      {isSmallScreen ? <Divider /> : null}
      <ListItem>
        <ListItemText>
          <LocalizedNavLink
            to="/products"
            activeClassName="active"
            onClick={onItemClicked}
          >
            {t9n?.Products}
          </LocalizedNavLink>
        </ListItemText>
      </ListItem>
      {isAuthenticated ? (
        <>
          {isSmallScreen ? <Divider /> : null}
          <ListItem>
            <ListItemText>
              <LocalizedNavLink
                to="/accessories"
                activeClassName="active"
                onClick={onItemClicked}
              >
                {t9n?.Accessories}
              </LocalizedNavLink>
            </ListItemText>
          </ListItem>
        </>
      ) : null}
    </List>
  );
};
export default NavMenu;
