import "./LocalizedNavLink.scss";

import { localeService } from "features/locale";
import React from "react";
import { NavLink } from "react-router-dom";

interface LocalizedNavLinkProps {
  to: string;
  exact?: boolean;
  activeClassName?: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  className?: string;
}
const LocalizedNavLink = ({
  children,
  to,
  exact,
  activeClassName,
  onClick,
  className,
}: React.PropsWithChildren<LocalizedNavLinkProps>) => {
  const baseI18nUrl = localeService.getI18nBaseUrl();
  return (
    <NavLink
      to={`${baseI18nUrl}${to}`}
      exact={exact}
      activeClassName={activeClassName}
      onClick={onClick}
      className={className}
    >
      {children}
    </NavLink>
  );
};

export default LocalizedNavLink;
