export const loadImage = (
  imageUrl: string,
  backgroundSize: string,
  width: number | string,
  height?: number | string
) => ({
  backgroundImage: `url(${imageUrl})`,
  backgroundPosition: "center",
  backgroundSize: backgroundSize,
  backgroundRepeat: "no-repeat",
  width: width,
  height: height ? height : width,
});

export const loadFavicon = (favicon: string) => {
  const faviconEl = document.getElementById("favicon") as HTMLLinkElement;
  faviconEl.href = favicon;
};
