import { Theme as syroxTheme, Styles as syroxStyles } from "./syrox/Theme";
import { Theme as cromaxTheme, Styles as cromaxStyles } from "./cromax/Theme";
import {
  Theme as spiesheckerTheme,
  Styles as spiesheckerStyles,
} from "./spieshecker/Theme";
import { Theme as MuiTheme } from "@mui/material";

const defaultBrand = "syrox";

export const getThemeByBrand = (brand: string): MuiTheme => {
  const theme = themeMap[brand.toLowerCase()];
  return theme ?? themeMap[defaultBrand];
};

const themeMap: Record<string, MuiTheme> = {
  syrox: syroxTheme,
  cromax: cromaxTheme,
  spieshecker: spiesheckerTheme,
};

export const getStylesPathByBrand = (brand: string): string => {
  const styles = stylesMap[brand.toLowerCase()];
  return styles ?? stylesMap[defaultBrand];
};

const stylesMap: Record<string, any> = {
  syrox: syroxStyles,
  cromax: cromaxStyles,
  spieshecker: spiesheckerStyles,
};
