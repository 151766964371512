import { localeService } from "features/locale";
import React from "react";
import { RouteProps, Switch } from "react-router";

const LocalizedSwitch = ({ children }: React.PropsWithChildren<{}>) => {
  function localizeRoutePath(path?: string | readonly string[]) {
    switch (typeof path) {
      case "undefined":
        return undefined;
      case "object":
        return `${localeService.getI18nBaseUrl()}` + path.join("/");
      default:
        const isFallbackRoute = path === "*";
        return isFallbackRoute
          ? path
          : `${localeService.getI18nBaseUrl()}` + path;
    }
  }

  return (
    <Switch>
      {React.Children.map(children, (child) =>
        React.isValidElement<RouteProps>(child)
          ? React.cloneElement(child, {
              ...child.props,
              path: localizeRoutePath(child.props.path),
            })
          : child
      )}
    </Switch>
  );
};

export default LocalizedSwitch;
