import { I18N } from "api/models";
import { RootState, useAppDispatch, useAppSelector } from "app";
import { getTranslations, localeService } from "features/locale";
import React from "react";
import { shallowEqual } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { cookieKeys, cookieService } from "services";

interface LocalizedRouterProps {
  RouterComponent: React.ComponentClass<any>;
}

const LocalizedRouter = ({
  children,
  RouterComponent,
}: React.PropsWithChildren<LocalizedRouterProps>) => {
  const { languages, countries } = useAppSelector(
    (state: RootState) => state.i18n,
    shallowEqual
  );

  // User selector is required here, this will render the proper url
  useAppSelector((state: RootState) => state.user.profile, shallowEqual);

  const dispatch = useAppDispatch();

  return (
    <RouterComponent>
      <Route path="/:country/:lang">
        {({ location }) => {
          let i18n: I18N = {};

          const i18nCookie = cookieService.get(cookieKeys.i18n);
          if (i18nCookie) i18n = JSON.parse(i18nCookie);

          const { country, lang } = i18n;
          if (country && lang) {
            if (
              countries.length > 0 &&
              languages.length > 0 &&
              (!localeService.isValid(countries, i18n.country) ||
                !localeService.isValid(languages, i18n.lang))
            ) {
              cookieService.tryDelete(cookieKeys.i18n);
              return <Redirect to="/" />;
            }

            if (!location.pathname.includes(`/${i18n.country}/${i18n.lang}`)) {
              const oldI18n = localeService.getI18nFromUrl();
              if (oldI18n && i18n.lang && oldI18n.lang !== i18n.lang) {
                dispatch(getTranslations(i18n.lang));
              }

              return (
                <Redirect
                  to={localeService.correctI18nUrl(
                    `/${i18n.country}/${i18n.lang}`
                  )}
                />
              );
            }
          }

          return <>{children}</>;
        }}
      </Route>
    </RouterComponent>
  );
};

export default LocalizedRouter;
