import { Button, Dialog } from "@mui/material";
import { Box } from "@mui/system";
import { userService } from "api/services";
import { useAppDispatch, useAppSelector } from "app";
import { selectT9n } from "features/locale";
import React, { useEffect, useState } from "react";
import createDOMPurify from "dompurify";
import { agreeLicense } from "features/terms";

const DOMPurify = createDOMPurify();

type Props = {
  open: boolean;
  onClose?: () => void;
};

const TermsPromptDialog = ({ open, onClose }: Props) => {
  const [licenseHtml, setLicenseHtml] = useState<string | null>();
  const t9n = useAppSelector(selectT9n);

  useEffect(() => {
    if (open) {
      const fetchLicense = async () => {
        const response = await userService.getLicenseAgreement();
        setLicenseHtml(response?.html);
      };

      fetchLicense();
    }
  }, [open]);

  const dispatch = useAppDispatch();

  const handleAgreeLicense = async () => {
    await userService.setAgreeLicense();
    dispatch(agreeLicense());
    onClose && onClose();
  };

  if (!licenseHtml) return <></>;

  return (
    <Dialog disableEscapeKeyDown open={open} onClose={onClose} maxWidth="xl">
      <Box p={2}>
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(licenseHtml),
          }}
        />

        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            sx={{ m: 2 }}
            onClick={handleAgreeLicense}
          >
            {t9n?.Agree}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default TermsPromptDialog;
